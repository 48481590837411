<template>
<!--  && !isSkeletonLoading.europeOdds -->
  <StatsWDL
    v-if="result"
    :key="result"
    @selectComparison="selectComparison"
    :isCompare="isCompare"
    :matchCompare="matchCompare"
    :result="result"
  />

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.europeOdds"></GeneralContentSkeleton> -->
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import StatsWDL from "@/components/matchListings/windrawlose/StatsWDL.vue";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
export default {
  components: { StatsWDL, GeneralContentSkeleton },
  data() {
    return {
      // isLoading: false,
      matchCompare: [
        { name: "OVERALL_COMPARISON" },
        { name: "HALF_TIME_COMPARISON" },
      ],
      isCompare: "OVERALL_COMPARISON",
      matchId: "",
      result: "",
      isSkeletonLoading: {
        europeOdds: false,
      },
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.getEuropeOddsData();
  },
  watch: {
    isCompare: {
      handler() {
        this.getEuropeOddsData();
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getEuropeOddsData();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["manualRefreshMatchDetail"]),
  },
  methods: {
    ...mapMutations([
      "MANUAL_REFRESH_MATCH_DETAIL",
    ]),
    ...mapActions(["getEuropeOdds"]),
    async getEuropeOddsData() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.europeOdds = true;
      }
      const result = await this.getEuropeOdds({
        matchId: this.matchId,
      });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.europeOdds = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      this.result = result.result;
    },
    selectComparison(data) {
      this.isCompare = data;
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.europeOdds) {
        this.MANUAL_REFRESH_MATCH_DETAIL();
      }
    },
  },
};
</script>

<style>
.matchList-highlighted td {
  background: #35454d;
  padding: 0.875rem 0;
}
.matchList-content-header,
.matchList-header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.matchList-expand {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
.matchList-checkbox_wrapper {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  margin: 0 0.5rem;
  max-width: 100px;
}
.matchList-checkbox_box {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  min-height: 1.25rem;
  margin-right: 0.375rem;
  background: url("../../../../../../static/images/icons/icon_filter_tick_unselect.png")
    center/contain no-repeat;
}
.matchList-checkbox_box.selected {
  background: url("../../../../../../static/images/icons/icon_filter_tick_white_selected.png")
    center/contain no-repeat;
}
section {
  margin: 1.5rem auto;
}
.matchList-container {
  width: 100%;
}
.matchList-content_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
}

.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  width: 48%;
  text-align: center;
}

.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  text-align: center;
}

.table-half {
  width: 48%;
}

.table-full {
  width: 100%;
}
.matchList-content_table-title {
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0.875rem 0 !important;
}

.matchList-content_title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0.625rem 0 0.625rem 1rem;
}

.matchList-content_table-subtitle th:nth-child(2),
.matchList-content_table-subtitle th:nth-child(3) {
  width: 35%;
}
.matchList-content_table th {
  font-weight: 700;
}
.matchList-content_table th,
.matchList-content_table td {
  border: 0.5px solid #4b616c;
  padding: 0.625rem 0.25rem;
}

.matchList-content_separator {
  font-weight: 700;
  background: #35454d;
}

.matchList-content_table tbody {
  background: #212b30;
}

.matchList-content_table td.no-stat {
  padding: 0.625rem 0.25rem !important;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

@media (max-width: 768px) {
  .navtab-wrapper {
    margin: 0 auto 1.5rem;/*1.5rem auto*/
    width: 95%;
  }
  .matchList-container,
  .matchList-tab {
    width: 95%;
  }
  .matchList-content_table {
    empty-cells: show;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .matchList-content_table-title,
  .matchList-content_title {
    font-size: 0.75rem;
    font-weight: 700;
    text-indent: unset;
  }
  .matchList-content_title {
    margin: 0.625rem 0;
  }

  .matchList-content_table .matchList-content_table-title {
    padding: 0.625rem 0;
  }
  .matchList-content_table th {
    border: 0.75px solid #4b616c;
    padding: 0.35rem 0;
    background: #35454d;
  }
  .matchList-content_table td {
    border: 0.75px solid #4b616c;
    padding: 0.375rem 0;
  }
  .matchList-tab {
    display: flex;
  }
  .matchList-tab button {
    background: none;
    color: #6f8c9b;
    border: 0;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: relative;
    padding: 0 0 6px 0;
    margin-right: 1rem;
  }
  .matchList-tab button:hover,
  .matchList-tab button.tab-selected {
    color: #fff;
  }
  .matchList-tab button.tab-selected::after,
  .matchList-tab button:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: #6f8c9b;
    border-radius: 2px;
  }

  .matchList-content_table tfoot td {
    padding: 0 !important;
    height: 1.875rem;
  }
  .matchList-content_separator button {
    background: #35454d;
    border: 1px solid #4b616c;
    border-radius: 8px;
    color: #fff;
    width: 95%;
    cursor: pointer;
    padding: 0.3rem 0;
    font-weight: 700;
    font-size: 0.75rem;
  }
  .matchList-content_separator button.active {
    background: #4b616c;
  }
}
</style>
